import { Box, CardMedia } from "@mui/material";
import XXL_BEACH_CHAIR from "../../assets/SBIcons/clean.png";
import XXL_WHEELCHAIR from "../../assets/SBIcons/disabled.png";
import FAMILY_BEACH_CHAIR from "../../assets/SBIcons/family.png";
import FKK_BUDJE from "../../assets/SBIcons/fkk-strand-2.png";
import DOG_BEACH_CHAIR from "../../assets/SBIcons/pawprint.png";
import SAUNA from "../../assets/SBIcons/sauna.png";
import CHILDBEACHCHAIR from "../../assets/SBIcons/sister-and-brother.png";
import XL_BEACH_CHAIR from "../../assets/SBIcons/size.png";
import BEACH_CHAIR_BED from "../../assets/SBIcons/sleeping.png";
import DAYBED from "../../assets/SBIcons/sofa.png";
import { EventBusy } from "@mui/icons-material";
import { customStyle } from "../customStyles/customStyles";

export interface IconBuilderProps {
  identifier?: string;
  grid?: boolean;
}

const iconPNG = {
  transform: "translate(190%, -65%)",
  height: "30px",
  width: "30px",
} as const;

const iconPNGGrid = {
  marginTop: "10px",
  height: "30px",
  width: "30px",
} as const;

const IconBuilder = ({ identifier, grid }: IconBuilderProps) => {
  switch (identifier) {
    case "WHEELCHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="WHEELCHAIR"
          component="img"
          src={XXL_WHEELCHAIR}
        />
      );
    case "XXL_WHEELCHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="XXL_WHEELCHAIR"
          component="img"
          src={XXL_WHEELCHAIR}
        />
      );
    case "SAUNA":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="SAUNA"
          component="img"
          src={SAUNA}
        />
      );
    case "DOG_BEACH_CHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="DOG_BEACH_CHAIR"
          component="img"
          src={DOG_BEACH_CHAIR}
        />
      );
    case "CHILDBEACHCHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="CHILDBEACHCHAIR"
          component="img"
          src={CHILDBEACHCHAIR}
        />
      );
    case "XL_BEACH_CHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="XL_BEACH_CHAIR"
          component="img"
          src={XL_BEACH_CHAIR}
        />
      );
    case "BEACH_CHAIR_BED":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="BEACH_CHAIR_BED"
          component="img"
          src={BEACH_CHAIR_BED}
        />
      );
    case "XXL_BEACH_CHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="XXL_BEACH_CHAIR"
          component="img"
          src={XXL_BEACH_CHAIR}
        />
      );
    case "FAMILY_BEACH_CHAIR":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="FAMILY_BEACH_CHAIR"
          component="img"
          src={FAMILY_BEACH_CHAIR}
        />
      );
    case "FKK_BUDJE":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="FKK_BUDJE"
          component="img"
          src={FKK_BUDJE}
        />
      );
    case "DAYBED":
      return (
        <CardMedia
          sx={grid ? iconPNGGrid : iconPNG}
          data-testid="DAYBED"
          component="img"
          src={DAYBED}
        />
      );
    case "EVENT":
      return (
        <EventBusy
          data-testid="isSeasonBeachChair"
          sx={{
            gridColumnStart: "2px",
            gridColumnEnd: "2px",
            color: customStyle.mainColor,
            transform: grid ? undefined : "translate(115%, -65%)",
            height: "30px !important",
            width: "40px !important",
          }}
        />
      );
    default:
      return <Box />;
  }
};

export default IconBuilder;
